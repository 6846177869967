define('fcxbde/components/tglc-post', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberInViewport.default, {
        lazy: true,
        didEnterViewport() {
            this.set('lazy', false);
        }
    });
});