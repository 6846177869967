define('fcxbde/mixins/loading-slider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Mixin, inject, isPresent } = Ember;

  exports.default = Mixin.create({
    loadingSlider: inject.service(),

    actions: {
      loading() {
        let loadingSliderService = this.get('loadingSlider');
        loadingSliderService.startLoading();
        if (isPresent(this.router)) {
          this.router.one('didTransition', function () {
            loadingSliderService.endLoading();
          });
        }
        if (this.get('bubbleLoadingSlider')) {
          return true;
        }
      },

      finished() {
        this.get('loadingSlider').endLoading();
      }
    }
  });
});