define('fcxbde/adapters/application', ['exports', 'ember-data', 'fcxbde/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        host: _environment.default.API,
        ajax: function (url, type, hash) {

            if (type === 'PUT') {
                type = 'POST';
            }

            return this._super(url, type, hash);
        }
    });
});