define('fcxbde/router', ['exports', 'fcxbde/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('posts', { path: '/' });
    this.route('post', { path: '/:id' });
  });

  exports.default = Router;
});