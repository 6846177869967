define('fcxbde/models/post', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        text: _emberData.default.attr('string'),
        score: _emberData.default.attr('string'),
        team1: _emberData.default.attr('string'),
        team2: _emberData.default.attr('string'),

        crdate: _emberData.default.attr('number'),
        crdate_str: _emberData.default.attr('string'),
        crdate_dif: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),

        images: _emberData.default.hasMany('images'),
        hour: _emberData.default.attr('string'),
        weather: _emberData.default.attr('string'),
        weather_icon: _emberData.default.attr('string'),
        weather_wind_speed: _emberData.default.attr('number'),
        weather_temperature: _emberData.default.attr('number'),

        weather_temperature_icon: Ember.computed('weather_temperature', function () {
            var date = new Date(this.get('crdate') * 1000);
            var month = date.getMonth();
            var seasonTemperature = [20, 30];

            switch (month) {
                case 0:
                case 1:
                case 2:
                case 11:
                    seasonTemperature = [0, 10];
                    break;
                case 3:
                case 4:
                case 9:
                case 10:
                    seasonTemperature = [10, 20];
                    break;
            }

            if (this.get('weather_temperature') > seasonTemperature[1]) {
                return '/favicons/Themperature_high.png';
            } else if (this.get('weather_temperature') < seasonTemperature[0]) {
                return '/favicons/Themperature_low.png';
            } else {
                return '/favicons/Themperature_medium.png';
            }
        }),
        google_maps_link: Ember.computed('location', function () {
            return 'https://maps.google.com/maps?t=m&f=d&saddr=Current+Location&daddr=' + this.get('location').replace(/\s/g, '+').replace(/,/g, '+');
        })
    });
});