define('fcxbde/components/tglc-add-post', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service('store'),
        router: Ember.inject.service(),

        open: false,
        loaderstate: '',
        post: Ember.computed(function () {
            if (this.get('edit')) {
                return this.get('edit');
            } else {
                return this.get('store').createRecord('post', {
                    'crdate': Math.round(Date.now() / 1000)
                });
            }
        }),

        options: {
            theme: "snow",
            modules: {
                toolbar: [["bold", "italic"], ["link"], ["clean"]]
            }
        },

        dropzoneOptions: Ember.computed(function () {
            return {
                maxFiles: 4
            };
        }),

        hasScore: false,
        hasTeams: false,
        hasLocation: false,
        buttontext: Ember.computed(function () {
            return this.get('edit') ? 'Edit Post' : 'Add Post';
        }),

        actions: {
            addedFileEvent(file) {
                this.send('addFile', file.xhr.response, file.name);
            },

            openForm() {
                this.set('open', true);
            },

            delete() {
                this.set('open', false);

                var post = this.get('post');
                var self = this;

                post.deleteRecord();
                post.save().then(function () {
                    self.reset();
                    self.get('router').transitionTo('posts');
                });
            },

            removedfileEvent(file) {
                var post = this.get('post');

                post.get('images').forEach(image => {
                    if (image.get('orig') === file.name) {
                        image.destroyRecord();
                    }
                });
            },
            addFile(file, orig) {
                var post = this.get('post');

                this.get('store').createRecord('image', {
                    url: file,
                    orig: orig,
                    post: post
                });
            },
            addScore() {
                this.set('hasScore', true);
                this.set('hasTeams', true);
            },

            addTeams() {
                this.set('hasTeams', true);
            },
            addLocation() {
                this.set('hasLocation', true);
            },
            updateText(editor) {
                this.set('post.text', editor.root.innerHTML);
            },
            close() {
                if (!this.get('edit')) {
                    this.set('open', false);
                } else {
                    this.get('router').transitionTo('posts');
                }
            },
            save() {
                var post = this.get('post');
                var self = this;

                this.set('loaderstate', 'loading');

                post.save().then(function (post) {
                    post.get('images').forEach(image => {
                        if (image.isNew) {
                            image.destroyRecord();
                        }
                    });

                    self.set('loaderstate', 'loaded');
                });

                if (!this.get('edit')) {
                    this.set('open', false);
                    this.reset();
                }
            }
        },

        reset() {
            this.set('post', this.get('store').createRecord('post', {
                crdate: Math.round(Date.now() / 1000),
                text: '',
                title: ''
            }));

            this.$('.ql-editor').html('');
            this.$('.dz-preview').remove();
            this.$('.dropzone').removeClass('dz-started');
        }
    });
});