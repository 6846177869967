define('fcxbde/models/image', ['exports', 'ember-data', 'fcxbde/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        url: _emberData.default.attr('string'),
        post: _emberData.default.belongsTo('post', { async: true }),
        orig: _emberData.default.attr('string'),
        svg: _emberData.default.attr('boolean'),

        absurl: Ember.computed('url', function () {
            return _environment.default.API + '/post_images/' + this.get('url');
        })
    });
});