define('fcxbde/components/tglc-quill', ['exports', 'ember-quill/components/quill-editor'], function (exports, _quillEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _quillEditor.default.extend({
    didInsertElement() {
      this._super(...arguments);

      //var editor = this.get('editor');


      //this.set(self, "editor", editor);
    }
  });


  function isWhitespace(ch) {
    var whiteSpace = false;
    if (ch == ' ' || ch == '\t' || ch == '\n') {
      whiteSpace = true;
    }
    return whiteSpace;
  }
});