define('fcxbde/controllers/posts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        newPost: Ember.computed('model.@each', function () {
            var posts = this.get('model');

            var filteredposts = posts.filter(function (post) {
                return post.get('isNew');
            });

            return filteredposts;
        }),

        sortedPosts: Ember.computed.sort('model', 'sortBy'),

        init() {
            this._super(...arguments);
            this.sortBy = ['crdate:desc'];
        }
    });
});