define('fcxbde/routes/post', ['exports', 'fcxbde/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resetScrollPosition.default, {
        model: function (params) {
            return this.store.find('post', params.id);
        }
    });
});